import React, {useEffect, useState} from 'react'
import {Paper, TextField, Typography} from "@mui/material"
import { getAuth, updatePassword } from "firebase/auth"
import LoadingButton from '@mui/lab/LoadingButton'
import {useHistory} from "react-router-dom";
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest} from "../../hooks/request";

export default function ResetLink() {
    const [email, setEmail] = useState(undefined)
    const {request, response, loading, error} = useLazyRequest(`customer/new-link`, REQUEST_METHOD.GET)
    const history = useHistory()

    return <Paper sx={{padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
        {response?.status === REQUEST_STATUS.ERROR && <Typography color='error'>Wrong email, try again or ask support..</Typography>}
        <TextField label="Email" required type='email' value={email} onChange={(event) => setEmail(event.target.value)} />
        <LoadingButton disabled={!email} loading={loading} onClick={() => request({email})} variant='outlined'>Sent Reset Link</LoadingButton>
        {response?.status === REQUEST_STATUS.SUCCESS && <Typography color='success'>Reset link sent by mail !</Typography>}
    </Paper>
}