import React, {useState} from 'react'
import {Box, Container, Tabs, Typography, Tab, Button} from "@mui/material";
import CustomerBidController from "../components/customerBidController";
import {useHistory} from "react-router-dom";
import Loading from "../components/loading";

const VALUE_TO_STATE = {
    inProgress: {state: 'OPEN'},
    accepted: {state: 'CLOSE', stateExtras: ['ACCEPTED']},
    cancelled: {state: 'CLOSE', stateExtras: ['CANCELED', 'REJECTED']},
}

export default function BidPage({}) {
    const [value, setValue] = useState('inProgress')
    const [states, setStates] = useState(VALUE_TO_STATE['inProgress'])

    const history = useHistory()

    const onValueChange = (_, value) => {
        setValue(value)
        setStates(VALUE_TO_STATE[value])
    }

    return (
        <Container maxWidth={"md"}>
            <Typography color={'text.primary'} sx={{textAlign: 'left', mb: 3, fontWeight: 'bold'}} variant="h4">My bids</Typography>

            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={onValueChange}  aria-label="basic tabs example">
                        <Tab label="In Progress" value="inProgress"/>
                        <Tab label="Accepted" value="accepted" />
                        <Tab label="Cancelled" value="cancelled" />
                    </Tabs>
                </Box>

                <CustomerBidController states={states} onSelect={(id) => history.push(`/bid/${id}`)}/>
            </Box>
        </Container>
    )
}