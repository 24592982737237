import React from 'react'
import {Box, Button, Typography} from "@mui/material";
import SignIn from "../../components/auth/signIn";
import {useHistory} from "react-router-dom";

export default function AuthPage() {
    const history = useHistory()
    return <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Typography color="text.primary" variant={"h3"}>STOCK.LU</Typography>
        <SignIn/>
        <Button color="primary" sx={{mt: 1}} size="small" onClick={() => history.push('/auth/reset')}>Request new password</Button>
    </Box>
}