import React from 'react'
import withWidth from "@mui/material/Hidden/withWidth";
import {
    AppBar,
    Box,
    Button,
    createTheme,
    IconButton,
    ThemeProvider,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import {useHistory} from "react-router-dom";
import BidBadge from "./components/bidBadge";
import {useScreenContext} from "./contexts/screen.context";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function AppLayout({children, width}) {
    const history = useHistory()
    const {windowHeight, size} = useScreenContext()
    const theme = useTheme()
    console.log(size)
    return (
        <Box height={windowHeight} sx={{height: windowHeight}}>
            <AppBar position="fixed" sx={{backgroundColor: 'grey.900', height: "65px", justifyContent: 'center'}}>
                <Toolbar>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignContent: 'center', flexGrow: 1}}>
                        <Typography variant="h4" component="h1" lineHeight={"unset"} onClick={() => history.push('/')}>STOCK.LU</Typography>
                        {size === 1 ? <Button color="inherit" sx={{ml: 3}} onClick={() => history.push('/')}>Products</Button> : undefined}
                    </Box>
                    <BidBadge>
                        <Button onClick={() => history.push('/bid')} color="inherit">My bids</Button>
                    </BidBadge>
                    <IconButton onClick={() => history.push('/profile')}><AccountCircleIcon/></IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{height: windowHeight - 85, paddingTop: "85px", overflow: 'scroll', backgroundColor: theme.palette.background}}>
                {children}
            </Box>
        </Box>
    )
}
export default withWidth()(AppLayout)