import React, {useEffect, useState} from 'react'

export const Context = React.createContext(null)

export const BREAK_POINT = {
    SMALL: 0, MEDIUM: 1
}

export default function ScreenContext({children}) {
    const [windowWidth, setWindowWidth] = useState(undefined);
    const [windowHeight, setWindowHeight] = useState(undefined);
    const [size, setSize] = useState(null)

    useEffect(() => {
        if (windowWidth <= 900)
            setSize(BREAK_POINT.SMALL)
        else
            setSize(BREAK_POINT.MEDIUM)
    }, [windowWidth])

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight)
        }
        window.addEventListener("resize", handleResize);
        handleResize()
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Context.Provider value={{size, windowHeight}}>
            {children}
        </Context.Provider>
    )
}

export const useScreenContext = () => (React.useContext(Context))

