import {useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Button, MenuItem, Select, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {ArrowUpward} from "@mui/icons-material";
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest} from "../hooks/request";
import {useHistory} from "react-router-dom";
import countryCode from '../components/data/countryCode.json'
import Flags from 'country-flag-icons/react/3x2'
import {Autocomplete} from "@mui/lab";

const variants = {
    enter: (dir) => ({
        y: dir > 0 ? 250 : -250,
        opacity: 0
    }),
    center: {
        y: 0,
        opacity: 1
    },
    exit: (dir) => ({
        y: dir < 0 ? 250 : -250,
        opacity: 0
    })
}

function SearchCountry({value, label, error, onChange}) {
    return (
        <>
            <div className={'flex items-center flex-row w-full space-x-2'}>
                {/*<RightArrow className={'fill-zinc-50 w-[15px]'} />*/}
                <Typography color={'text.primary'} className={'font-bold'}>{label}</Typography>
            </div>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    onChange(newValue.code)
                }}
                clearOnBlur
                autoFocus
                handleHomeEndKeys
                options={countryCode}
                renderOption={(props, option) => {
                    const Flag = Flags[option.code]
                    if (Flag)
                        return <li {...props}><Flag style={{width: 15, marginRight: 5}}/>{option.name}</li>
                    return <li {...props}>{option.name}</li>

                }}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%" }}
                freeSolo
                renderInput={(params) => (
                    <TextField autocorrect="off" autoFocus variant={'standard'} {...params} placeholder={'Type your answer here...'} />
                )}
            />
        </>
    )
}

function PhoneNumber({value, label, error, onChange}) {
    const [_value, setValue] = useState({dial: '+352', phone: ''})

    const _onChange = (field, __value) => {
        let tmp = {..._value}
        tmp[field] = __value
        setValue(tmp)
        onChange(`${tmp.dial} ${tmp.phone}`)
    }

    return (
        <>
            <div className={'flex items-center flex-row w-full space-x-2'}>
                {/*<RightArrow className={'fill-zinc-50 w-[15px]'} />*/}
                <Typography color={'text.primary'} className={'font-bold'}>{label}</Typography>
            </div>
            <Box sx={{display: 'flex', gap: 1, width: '100%'}}>
                <Select
                    value={_value.dial}
                    variant={'standard'}
                    onChange={({target: {value}}) => _onChange('dial', value)}
                >
                    {countryCode.map((elem) => {
                        const Flag = Flags[elem.code]
                        if (Flag)
                            return <MenuItem key={elem.code} value={elem.dial_code}><Flag style={{width: 15}}/>{elem.dial_code}</MenuItem>
                        return <MenuItem key={elem.code} value={elem.dial_code}>{elem.dial_code}</MenuItem>
                    })}
                </Select>
                <TextField
                    sx={{width: '100%'}}
                    autoFocus
                    multiple
                    type={'tel'}
                    variant={'standard'}
                    error={error}
                    placeholder={'Type your answer here...'}
                    className={`appearance-none transition bg-black py-3 w-full border-b border-b-zinc-700 outline-0 text-xl focus:border-b-zinc-500 ${error && 'text-red-400 border-b-red-400 focus:border-b-red-400'}`}
                    value={_value.phone}
                    onChange={({target: {value}}) => _onChange('phone', value)}
                />
            </Box>

        </>
    )
}

function EmailInputs({value, label, error, onChange}) {
    return (
        <>
            <div className={'flex items-center flex-row w-full space-x-2'}>
                {/*<RightArrow className={'fill-zinc-50 w-[15px]'} />*/}
                <Typography color={'text.primary'} className={'font-bold'}>{label}</Typography>
            </div>
            <TextField
                autoFocus
                autocapitalize="none"
                multiple
                type='email'
                autocorrect="off"
                variant={'standard'}
                error={error}
                placeholder={'Type your answer here...'}
                className={`appearance-none transition bg-black py-3 w-full border-b border-b-zinc-700 outline-0 text-xl focus:border-b-zinc-500 ${error && 'text-red-400 border-b-red-400 focus:border-b-red-400'}`}
                value={value} onChange={onChange} />
            {error && typeof error === 'string' ? <Typography color={'error.main'}>{error}</Typography> : null}

        </>
    )
}

function FormInputs({value, label, error, onChange}) {
    return (
        <>
            <div className={'flex items-center flex-row w-full space-x-2'}>
                {/*<RightArrow className={'fill-zinc-50 w-[15px]'} />*/}
                <Typography color={'text.primary'} className={'font-bold'}>{label}</Typography>
            </div>
            <TextField
                autocapitalize="none"
                autoFocus
                autocorrect="off"
                multiple
                variant={'standard'}
                error={error}
                placeholder={'Type your answer here...'}
                className={`appearance-none transition bg-black py-3 w-full border-b border-b-zinc-700 outline-0 text-xl focus:border-b-zinc-500 ${error && 'text-red-400 border-b-red-400 focus:border-b-red-400'}`}
                value={value} onChange={onChange} />
            {error && typeof error === 'string' ? <Typography color={'error.main'}>{error}</Typography> : null}
        </>
    )
}

function isEmail(from, target) {
    return !(from[target].includes('.') && from[target].includes('@'))
}

function checkMinusOne(from, target) {
    if (!(from[target].toLowerCase() === from[target - 1].toLowerCase())) {
        return 'This Email Address does not match the first one given'
    } else {
        return false
    }
}

const form = [
    {key: 'companyName', label: 'What\'s your Company name ?',  type: 'input'},
    {key: 'areaCode', label: 'In which country is your company ?',type: 'country'},
    {key: 'name', label: 'What\'s your Name ?',type: 'input'},
    {key: 'phoneNumber', label: 'What\'s your phone number ?',type: 'phone'},
    {key: 'email', label: 'What\'s your email address ?',type: 'email', validate: isEmail},
    {key: 'confirmEmail', label: 'Confirm your email address.',type: 'email', validate: checkMinusOne}
]

export default function OnBoardingPage({}) {
    const {response, loading, request} = useLazyRequest(`customer/lead`, REQUEST_METHOD.PUT)
    const [[index, direction], setIndex] = useState([-1, 0])
    const [error, setError] = useState(false)
    const [res, setRest] = useState([])
    const [size, setSize] = useState([0, 0])
    const history = useHistory()

    const onNext = async (dir) => {
        if (index >= form.length) {
            return
        } else if (index >= 0 && dir > 0) { //If we move to the next check the validate function
            if (!res[index] || res[index].length <= 0) {
                setError(true)
                return
            }
            else if (form[index].validate) {
                let _error = form[index].validate(res, index)
                if (_error) {
                    setError(_error)
                    return
                }
            }
        }

        if ((index + dir) >= form.length) { //If we reach the end on the next create Request body and do the request
            let body = {}
            form.forEach((elem, index) => {
                body[elem.key] = res[index]
            })
            if (!response) {
                await request({}, body)
            }
        }
        setIndex([index + dir, dir])
    }

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            window.localStorage.setItem('lead', JSON.stringify({lead: response.data, res}))
        }
    }, [response])

    const onChange = (event) => {
        let value
        if (typeof event === 'string') value = event
        else value = event.target.value
        setError(false)
        const _res = [...res]
        _res[index] = value
        setRest(_res)
    }

    const renderNext = (title) => (
        <Box  sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <Button variant={'contained'} onClick={() => onNext(1)}>{title}</Button>
            <Typography color={'text.primary'} sx={{display: 'flex'}}>or press <Typography color={'text.primary'} sx={{fontWeight: 'bold', marginLeft: 1}}>Enter ↵</Typography></Typography>
        </Box>
    )

    const renderContent = () => {
        if (index < 0) {
            return (
                <div>
                    <Box sx={{marginBottom: '1rem'}} className={'space-y-1 mb-5'}>
                        <Typography variant={'h3'} color={'text.primary'}>Stock.lu</Typography>
                        <Typography color={'text.primary'}>Welcome. You will now be guided through the initial on-boarding process.</Typography>
                    </Box>
                    {renderNext('Start')}
                </div>
            )
        } else if (index >= form.length) {
            return (
                <>
                    <Box sx={{marginBottom: '1rem'}} className={'space-y-1 mb-5'}>
                        <Typography variant={'h3'} color={'text.primary'}>Thank you.</Typography>
                        <Typography color={'text.primary'}>We will get back to you as soon as possible</Typography>
                    </Box>
                    {/*{renderNext('Back to /')}*/}
                </>
            )
        }  else if (form[index].type === 'country') {
            return (
                <>
                    <SearchCountry  error={error} label={form[index].label} value={res[index]} onChange={onChange}/>
                    {renderNext('Next')}
                </>
            )
        } else if (form[index].type === 'email') {
            return (
                <>
                    <EmailInputs  error={error} label={form[index].label} value={res[index]} onChange={onChange}/>
                    {renderNext('Next')}
                </>
            )
        }  else if (form[index].type === 'phone') {
            return (
                <>
                    <PhoneNumber  error={error} label={form[index].label} value={res[index]} onChange={onChange}/>
                    {renderNext('Next')}
                </>
            )
        } else {
            return (
                <>
                    <FormInputs  error={error} label={form[index].label} value={res[index]} onChange={onChange}/>
                    {renderNext('Next')}
                </>
            )
        }
    }

    const onKeyUp = ({key}) => {
        if (key === 'Enter') onNext(1)
    }

    useEffect(() => {
        document.addEventListener('keyup', onKeyUp)

        return () => document.removeEventListener('keyup', onKeyUp)
    }, [res, index])

    const onResize = () => {
        setSize([window.innerWidth, window.innerHeight])
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        onResize()
        return () => window.removeEventListener('resize', onResize)
    }, [])


    return (
        <>
            <Box sx={{display: 'flex', position: 'relative'}} className={'flex relative justify-center items-center'} style={{width: size[0], height: size[1]}}>
                <Box sx={{position: 'fixed', top: 0, left: 0, height: '.5rem', backgroundColor: 'primary.main'}} style={{width: `${((index + 1) / (form.length + 1)) * 100}%`}}  />
                {index >= 0 &&
                    <motion.div
                        animate={{
                            top: 25,
                            opacity: 1
                        }}
                        transition={{type: 'linear'}}
                        initial="exit"
                        style={{position: 'fixed', left: 25, top: 50, opacity: 0}} >
                        <Typography onClick={() => history.push('/')} variant={'h5'} color={'text.primary'}>
                            Stock.lu
                        </Typography>
                    </motion.div>}

                <AnimatePresence exitBeforeEnter custom={direction}>
                    <motion.div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        key={`${index}`}
                        custom={direction}
                        variants={variants}
                        transition={{type: 'linear'}}
                        initial="enter"
                        animate="center"
                        exit="exit">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            width: {
                                xs: "90%", // theme.breakpoints.up('xs')
                                md: "55%", // theme.breakpoints.up('md')
                            }
                        }}>
                            {renderContent()}
                        </Box>
                    </motion.div>
                </AnimatePresence>
                {index >= 0 && <Box onClick={() => {
                    setError(false)
                    onNext(-1)
                }} sx={{cursor: 'pointer', position: 'fixed', display: 'flex', bottom: 25, right: 25}} color={'text.primary'} ><Typography sx={{fontWeight: 'bold', fontSize: '1.2rem'}}>Back</Typography> <ArrowUpward sx={{width: 50}} /></Box>}
            </Box>
        </>
    )

}