import React, {useEffect, useState} from 'react'
import {REQUEST_STATUS, useRequest} from "../hooks/request";
import ProductList from "./productList";
import Loading from "./loading";
import ProductListItem from "./productListItem";
import {Typography} from "@mui/material";

export default function ProductListController({search}) {
    const {refetch, loading, response} = useRequest('customer/products')
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (!loading) {
            refetch({search})
        }
    }, [search])

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setProducts(response.data)
        }
    }, [response])

    return (
        <Loading loading={loading}>
            <Typography sx={{mt: 1, fontSize: 14}} color={'text.primary'}>You can send a bid by changing the price value !</Typography>
            {products.map(product => <ProductListItem product={product}/>)}
            {/*<ProductList products={products} />*/}
        </Loading>
    )
}