import React from 'react'
import PrivateRoute from "./privateRoute";
import {
    BrowserRouter as Router, Route,
    Switch
} from "react-router-dom";
import {Container} from "@mui/material";
import MarketPage from "../pages/market.page";
import BidPage from "../pages/bid.page";
import BidDetailsPage from "../pages/bidDetails.page";
import CreateBidPage from "../pages/createBid.page";
import AuthPage from "../pages/auth/auth.page";
import SignInEmailPage from "../pages/auth/signInEmail.page";
import SetPasswordPage from "../pages/auth/setPassword.page";
import {useAuth} from "../contexts/auth.context";
import Loading from "../components/loading";
import ProfilePage from "../pages/profile.page";
import NewPasswordPage from "../pages/auth/newPassword.page";
import OnBoardingPage from "../pages/onBoarding.page";

export default function AppRouter() {
    const {loading} = useAuth()

    return (
        <Loading loading={loading}>
            <Router>
                <Switch>
                    <Route path="/auth/signIn">
                        <AuthPage/>
                    </Route>
                    <Route path="/auth/emailSignIn">
                        <SignInEmailPage/>
                    </Route>
                    <Route path="/auth/setPassword">
                        <SetPasswordPage/>
                    </Route>
                    <Route path="/auth/reset">
                        <NewPasswordPage/>
                    </Route>
                    <Route exact path="/on-boarding">
                        <OnBoardingPage />
                    </Route>

                    <PrivateRoute exact path="/">
                        <MarketPage/>
                    </PrivateRoute>

                    <PrivateRoute exact path="/market/:productId">
                        <CreateBidPage/>
                    </PrivateRoute>

                    <PrivateRoute exact path="/bid/">
                        <BidPage/>
                    </PrivateRoute>

                    <PrivateRoute exact path="/bid/:bidId">
                        <BidDetailsPage/>
                    </PrivateRoute>

                    <PrivateRoute exact path="/profile">
                        <ProfilePage/>
                    </PrivateRoute>

                </Switch>
            </Router>
        </Loading>
    )
}