import React, {useState} from 'react'
import {Button, Paper, TextField, Typography} from "@mui/material"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import LoadingButton from '@mui/lab/LoadingButton'
import {useHistory} from "react-router-dom";
import {Box} from "@mui/system";

export default function SignIn() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [error, setError] = useState(undefined)
    const history = useHistory()

    const handleSignIn = () => {
        setLoading(true)
        const auth = getAuth()
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setLoading(false)
                history.replace(`/`)
            })
            .catch((error) => {
                setLoading(false)
                setError(error.message)
            })
    }

    return (
        <>
            <Paper sx={{padding: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
                {error && <Typography color='error'>{error}</Typography>}
                <TextField label="Email" required type='email' value={email} onChange={(event) => setEmail(event.target.value)} />
                <TextField label="Password" required type='password' value={password} onChange={(event) => setPassword(event.target.value)}/>
            </Paper>

            <Box sx={{mt: 2, gap: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Button color={'primary'} variant={'outlined'} onClick={() => history.push('/on-boarding')}>Sign up</Button>
                <LoadingButton disabled={!email || !password} loading={loading} onClick={() => handleSignIn()} variant='contained'>Sign In</LoadingButton>
            </Box>
        </>

    )
}