import React, {useEffect, useState} from 'react'
import {Button, Typography} from "@mui/material";
import ResponsiveTable from "./responsiveTable";

const getStateWording = (state) => {
    switch (state) {
        case 'OPEN':
            return 'On review'
        case 'REJECTED':
            return <Typography sx={{color: 'primary.main'}} fontWeight="bold">Action required</Typography>
        case 'ACCEPTED':
            return <Typography sx={{color: 'success.main'}} fontWeight="bold">Complete</Typography>
        case 'CANCELED':
            return <Typography sx={{color: 'error.main'}} fontWeight="bold">Closed</Typography>
    }
}

export default function CustomerBidList({bids, onSelect}) {
    const [formatted, setFormatted] = useState(null)

    useEffect(() => {
        if (bids) {
            let rows =  []
            for (let bid of bids) {
                let res = {
                    id: bid.id,
                    cells: [
                        {header: 'Product', value: bid.product.name, mobileValueOnly: true},
                        {header: 'State', value: getStateWording(bid.stateExtra)},
                        {header: 'Price', value: bid.price.toFixed(2), textAlign: 'right'},
                        {header: 'Quantity', value: bid.stock, textAlign: 'right'},
                    ]
                }
                if (onSelect) {
                    res.onClick = () => onSelect(bid.id);
                    res.cells.push({header: null, value: <Button fullWidth onClick={() => onSelect(bid.id)} variant='outlined'>Details</Button>, mobileValueOnly: true})
                }
                rows.push(res)
            }
            setFormatted({rows})
        }
    }, [bids])

    return <ResponsiveTable data={formatted} />
}