import React, {createContext, useEffect, useState} from 'react'
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

export const Context = createContext(null)

const loadUser = () => {
    let unparsed = localStorage.getItem('user')
    if (unparsed) {
        let parsed = JSON.parse(unparsed)
        if (parsed && parsed.token) return parsed
    }
    return null
}

const firebaseConfig = {
    apiKey: "AIzaSyBnLKu-dbWFZphsxYdqpEZq1mYjXXnenf8",
    authDomain: "mono-2594b.firebaseapp.com",
    projectId: "mono-2594b",
    storageBucket: "mono-2594b.appspot.com",
    messagingSenderId: "188267864389",
    appId: "1:188267864389:web:a06c3552f2afac37655e2c"
};

export default function AuthContext({children}) {
    initializeApp(firebaseConfig);
    const [user, setUser] = useState(loadUser())
    const [loading, setLoading] = useState(true)
    const auth = getAuth();

    useEffect(() => {
        auth.onAuthStateChanged(async user => {
            if (user) { //user signed in
                setUser ({token: await auth.currentUser.getIdToken()})
                setLoading(false)
            }
            else { //user signed out
                setUser(undefined)
                setLoading(false)
            }
        })
    }, []);

    useEffect(() => {
        if (user) localStorage.setItem('user', JSON.stringify(user))
    }, [user])

    const requestToken = async () => {
        if (!auth?.currentUser) return undefined;
        const token = await auth.currentUser.getIdToken(true)
        setUser(user ? {...user, token: token} : {token: token})
        return token
    }

    return (
        <Context.Provider value={{user, setUser, requestToken, loading}}>
            {children}
        </Context.Provider>
    )
}

export const useAuth = () => (React.useContext(Context))