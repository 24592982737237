import React from 'react'
import {Box} from "@mui/system";
import {CircularProgress} from "@mui/material";

export default function Loading({loading, children}) {
    return (
        loading ?
            <Box sx={{display: 'flex', justifyContent: 'center', py: 2}}>
                <CircularProgress size={50}/>
            </Box>
            :
            <React.Fragment>
                {children}
            </React.Fragment>

    )
}

