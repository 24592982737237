import React from 'react'
import {Box, Card, Container, Typography} from "@mui/material";
import SignOut from "../components/auth/signOut";
import {useAuth} from "../contexts/auth.context";
import {useRequest} from "../hooks/request";

export default function ProfilePage() {
    const {response, loading} = useRequest('customer/me')


    return  <Container maxWidth={"md"}>
        <Typography color={'text.primary'} variant="h3" component="h2">Profile</Typography>
        {response?.data?.email && <Card sx={{p: 2}}>
            <Typography fontSize={18}>{response.data.name}</Typography>
            <Typography>{response.data.email}</Typography>
            <Box sx={{justifyContent: 'center', display: 'flex'}}>
                <SignOut/>
            </Box>
        </Card>}
    </Container>
}