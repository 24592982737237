import React, {useEffect, useState} from 'react'
import {Box, Container, TextField, Typography} from "@mui/material";
import ProductListController from "../components/productListController";
import {useHistory} from "react-router-dom";

export default function MarketPage() {
    const [search, setSearch] = useState()
    const history = useHistory()

    return (
        <Container maxWidth={"md"}>
            <Box sx={{display: {xs: "gide", sm: "flex"}, justifyContent: "space-between"}}>
                <Typography color={'text.primary'} variant="h3" component="h2">Products</Typography>
                <TextField onChange={({target: {value}})=> setSearch(value)} sx={{width: {xs: 1, sm: "auto"}}} variant="outlined" label="Search product..." />
            </Box>
            <ProductListController search={search}/>
        </Container>
    )

}