import {useEffect, useState} from 'react'
import axios from "axios";
import {useRefetchContext} from "../contexts/refetch.context";
import {useAuth} from "../contexts/auth.context";
import {useHistory} from "react-router-dom";

const baseURL = process.env.API_URL || 'https://monoapi.weweel.dev'
//const baseURL = process.env.API_URL || 'http://localhost:8080'

export const REQUEST_METHOD = {
    PUT: 'put',
    GET: 'get',
    POST: 'post',
    DELETE: 'delete'
}

export const REQUEST_STATUS = {
    SUCCESS: 'success',
    ERROR: 'error'
}

export const useRequest = (url, method = REQUEST_METHOD.GET, params = {}, body = {}) => {
    const [loading, setLoading]     = useState(true)
    const [data, setData]           = useState()
    const {toRefetch}               = useRefetchContext()
    const {user, requestToken}                    = useAuth()

    const history = useHistory()

    const fetch = (params, body, retry = 0) => {
        setLoading(true)
        axios({
            baseURL,
            method,
            headers: {'Authorization': user?.token ? `Bearer ${user.token}` : undefined},
            url,
            params,
            data: body
        }).then((response) => {
            setLoading(false)
            if (response.status !== 200) {
                setData({status: REQUEST_STATUS.ERROR})
            } else {
                setData({status: REQUEST_STATUS.SUCCESS, data: response.data})
            }
        }).catch(async (error) => {
            setLoading(false)
            setData({status: REQUEST_STATUS.ERROR})
            if (error.response && error.response.status === 401 && retry < 3) {
                const token = await requestToken()
                if (token) fetch(params, body, retry + 1 )
            } else if (error.response && error.response.status === 401) {
                localStorage.removeItem('user')
                history.push('/auth/signIn')
            }
        })
    }

    useEffect(() => {
        if (toRefetch && Array.isArray(toRefetch) && toRefetch.find(elem => elem === url) && method === REQUEST_METHOD.GET) {
            fetch(params, body)
        }
    }, [toRefetch])

    useEffect(() => {
        fetch(params, body)
    }, [])

    return {refetch: fetch, loading, response: data}
}

export const useLazyRequest = (url, method = REQUEST_METHOD.GET) => {
    const [loading, setLoading] = useState(false)
    const [data, setData]       = useState(undefined)
    const {toRefetch}           = useRefetchContext()

    const [params, setParams]   = useState(null) //used for the toReload event
    const [body, setBody]       = useState(null) //used for the toReload event
    const {user, requestToken}                = useAuth()

    const history = useHistory()

    const request = (_params = {}, _body = {}, retry = 0) => {
        if (_params !== params) setParams(_params)
        if (_body !== body) setBody(_body)

        setLoading(true)
        setData(undefined)

        axios({
            baseURL,
            url,
            headers: {'Authorization': user?.token ? `Bearer ${user.token}` : undefined},
            method,
            params: _params,
            data: _body
        }).then((response) => {
            setLoading(false)
            if (response.status !== 200) {
                setData({status: REQUEST_STATUS.ERROR, data: response.data})
            } else {
                setData({status: REQUEST_STATUS.SUCCESS, data: response.data})
            }
        }).catch(async (error) => {
            setLoading(false)
            setData({status: REQUEST_STATUS.ERROR})
            if (error.response && error.response.status === 401 && retry < 3) {
                const token = await requestToken()
                if (token) request(params, body, retry + 1 )
            } else if (error.response && error.response.status === 401) {
                localStorage.removeItem('user')
                history.push('/auth/signIn')
            }
        })
    }

    useEffect(() => {
        if (data && toRefetch && Array.isArray(toRefetch) && toRefetch.find(elem => elem === url) && method === REQUEST_METHOD.GET) {
            request(params, body)
        }
    }, [toRefetch])

    return {request, loading, response: data}
}