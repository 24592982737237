import {createTheme} from "@mui/material/styles";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#D97136'
        },
        info: {
            main: '#369dd9'
        },
        error:  {
            main: '#d9364c'
        },
        warning: {
            main: '#d9c336'
        },
        success: {
            main: '#9dd936'
        },
    },
    typography: {
        color: '#fff',
        h4: {
            fontWeight: 700,
        },
        h3: {
            fontWeight: 700,
        },
        h2: {
            fontWeight: 800,
        },
        h1: {
            fontWeight: 900,
        },
    },
});

export default darkTheme