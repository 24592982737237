import React from 'react'
import {BREAK_POINT, useScreenContext} from "../contexts/screen.context";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";

function Desktop({data}) {

    const drawCell = (cell) => (
        <TableCell sx={{textAlign: cell.textAlign}}>{cell.value}</TableCell>
    )

    const drawRow = (datum) => (
        <TableRow onClick={datum.onClick} key={datum.id} hover sx={{":hover": {cursor: 'pointer'}}}>
            {datum.cells.map(drawCell)}
        </TableRow>
    )

    const drawHeader = (cell) => (
        <TableCell sx={{textAlign: cell.textAlign}}>{cell.header}</TableCell>
    )

    return (
        <TableContainer sx={{my: 2}}>
            <Table>
                <TableHead>
                    <TableRow>
                        {data.rows[0] && data.rows[0].cells.map(drawHeader)}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.rows.map(drawRow)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function Mobile({data}) {

    const drawCell = (cell) => {
        if (cell.mobileValueOnly)
            return (<Typography sx={{color: 'text.primary'}}>{cell.value}</Typography>)
        else
            return (
                <Box sx={{display: "flex", alignItems: 'center'}}>
                    <Typography sx={{color: 'text.primary'}}>{cell.header} :</Typography>
                    <Box sx={{flex: 1, height: "1px", backgroundColor: '#424242'}} />
                    <Typography sx={{color: 'text.primary'}}>{cell.value}</Typography>
                </Box>
            )
    }

    const drawRaw = (row, index) => (
        <Box key={`${index}`} sx={{display: 'grid', gap: 2, py: 2, borderBottom: '1px solid #dedede'}}>
            {row.cells.map(drawCell)}
        </Box>
    )

    return (
        <Box>
            {data.rows.map(drawRaw)}
        </Box>
    )
}

export default function ResponsiveTable({data, emptyMessage = "No data available"}) {
    const {size} = useScreenContext()

    if (data) {
        if (!data.rows || !data.rows.length) {
            return (
                <Box sx={{py: 2, width: 1, display: 'flex', justifyContent: 'center'}}>
                    <Typography color="text.primary" >{emptyMessage}</Typography>
                </Box>
            )
        }
        else if (size === BREAK_POINT.SMALL) {
            return <Mobile data={data}/>
        } else {
            return <Desktop data={data}/>
        }
    } else {
        return <Box/>
    }
}
