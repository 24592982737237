import React, {useState} from 'react'
import { getAuth } from "firebase/auth"
import {Button} from "@mui/material";

export default function SignOut() {
    const handleSignOut = () => {
        getAuth().signOut();
    }

    return <Button color="error" onClick={handleSignOut} >
        Sign Out
    </Button>
}