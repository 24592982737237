import React from 'react'
import {REQUEST_METHOD, REQUEST_STATUS, useRequest} from "../hooks/request";
import {Badge} from "@mui/material";

export default function BidBadge({children}) {
    const {response} = useRequest('customer/count-bids', REQUEST_METHOD.GET, {states: ['REJECT']})

    return (
        <Badge showZero={false} color="primary" badgeContent={response && response.status === REQUEST_STATUS.SUCCESS && response.data ? response.data : 0}>
            {children}
        </Badge>
    )
}