import react, {useEffect, useState} from 'react'
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest} from "../hooks/request";
import {Box} from "@mui/system";
import CustomerBidList from "./customerBidList";
import {CircularProgress, LinearProgress} from "@mui/material";
import Loading from "./loading";

export default function CustomerBidController({states, onSelect}) {
    const [bids, setBids] = useState([])
    const {request, refetch, loading, response} = useLazyRequest('customer/bid', REQUEST_METHOD.GET, {state: states.state, stateExtras: states.stateExtras})

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setBids(response.data)
        }
    }, [response])

    useEffect(() => {
        if (bids.length) setBids([])
        request({state: states.state, stateExtras: states.stateExtras})
    }, [states])

    return (
        <Loading loading={loading}>
            <CustomerBidList bids={bids} onSelect={onSelect}/>
        </Loading>
    )
}