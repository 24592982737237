import React, {useEffect, useState} from 'react'
import {Chip} from "@mui/material";
import ResponsiveTable from "./responsiveTable";
import moment from "moment";

export default function OfferList({offers, selected}) {
    const [formatted, setFormatted] = useState(null)

    useEffect(() => {
        if (offers) {
            let rows = []
            for (let offer of offers) {
                rows.push({
                    id: offer.id,
                    cells: [
                        {header: 'Price', value: offer.price.toFixed(2), textAlign: 'right'},
                        {header: 'Stock', value: offer.stock, textAlign: 'right'},
                        // {header: 'Min quantity', value: offer.minQuantity ? offer.minQuantity : '-', textAlign: 'right'},
                        {header: 'ETA', value: offer.estimatedTimeOfArrival ? moment().add('d', offer.estimatedTimeOfArrival).format('dddd DD. MMM.') : '-', textAlign: 'right'},
                    ]
                })
            }

            setFormatted({rows})
        }
    }, [offers, selected])

    return <ResponsiveTable data={formatted}/>
}