import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRouter from "./router/appRouter";
import AuthContext from "./contexts/auth.context";
import RefetchContext from "./contexts/refetch.context";
import {ThemeProvider} from "@mui/material/styles";
import theme from "./theme";
import ScreenContext from "./contexts/screen.context";



ReactDOM.render(
    <React.StrictMode>
        <ScreenContext>
            <AuthContext>
                <RefetchContext>
                    <ThemeProvider theme={theme}>
                        <AppRouter/>
                    </ThemeProvider>
                </RefetchContext>
            </AuthContext>
        </ScreenContext>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
