import React, {useEffect, useState} from 'react'
import {Button, IconButton, TextField, Box, Typography, Paper} from "@mui/material";
import {useHistory} from "react-router-dom";
import CreateBidForm from "./createBidForm";

export default function ProductListItem({product}) {
    if (!product?.book[0]?.stock) return <Box/>
    const estimatedTimeOfArrival = product?.book[0]?.meta?.estimatedTimeOfArrival

    return <Paper elevation={1} sx={{p: 2, my:5, display: "flex", flexDirection: 'column'}}>
        <Typography sx={{textAlign: 'left'}}>{product.name}</Typography>
        {(estimatedTimeOfArrival && +estimatedTimeOfArrival > 0) ? <Typography variant={'caption'}><em>ETA: {estimatedTimeOfArrival} days</em></Typography> : null}
        <CreateBidForm sell={product?.book[0]}/>
    </Paper>
}