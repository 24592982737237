import React, {useEffect, useState} from 'react'
import {Button, FormGroup, TextField, Box, CircularProgress, Typography} from "@mui/material";
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest} from "../hooks/request";
import {CheckCircle} from "@mui/icons-material";
import {useHistory} from "react-router-dom";

export default function CreateBidForm({sell}) {
    const [quantity, setQuantity] = useState(undefined)
    const [price, setPrice] = useState(undefined)
    const [minQuantity, setMinQuantity] = useState()
    const [maxQuantity, setMaxQuantity] = useState()

    const [priceError, setPriceError] = useState()
    const [quantityError, setQuantityError] = useState()

    const {request, response, loading} = useLazyRequest(`customer/bid`, REQUEST_METHOD.POST)

    const history = useHistory()

    const generateBid = () => {
        request(null, {
            price: +price,
            stock: +quantity,
            productId: sell.productId,
            parentId: sell.id,
            meta: {}
        })
    }

    useEffect(() => {
        if (sell) {
            setPrice(sell.price ? +sell.price : undefined)
            setQuantity(sell.stock)
            setMaxQuantity(sell.stock)
            setMinQuantity(sell.minQuantity ? sell.minQuantity : 0)
        }
    }, [sell])

    useEffect(() => {
        //TODO For test purposes only, need to find a correct rule with mono
        // if (sell) {
        //     let diff = sell.price - price
        //     if (diff > ((sell.price * 2) / 100)) {
        //         setPriceError('Your bid is too low')
        //         return
        //     }
        //     setPriceError(null)
        // }
        //TODO end

    }, [price, sell])

    useEffect(() => {
        if ((quantity < minQuantity || quantity > maxQuantity) || isNaN(+quantity)) {
            setQuantityError("Incorrect quantity")
        } else {
            setQuantityError(null)
        }
    }, [quantity])

    return (
        <Box>
            <Box sx={{display: !loading && !response ? "flex" : 'none', my: 2}}>
                <TextField  sx={{mx: 1}}  InputLabelProps={{ shrink: true }} size={'small'} error={quantityError} onFocus={(event => event.target.select())} helperText={quantityError} fullWidth value={quantity} onChange={({target: {value}}) => setQuantity(value)} label="Quantity" inputProps={{min: minQuantity, inputMode: "numeric", pattern: "[-+]?[0-9]*[.,]?[0-9]+", type: "tel"}} variant="outlined"/>
                <TextField  sx={{mx: 1}} InputLabelProps={{ shrink: true }} size={'small'} error={priceError} helperText={priceError} fullWidth value={price} onFocus={(event => event.target.select())}
                            onChange={({target: {value}}) => setPrice(value.replace(/[,]/g, '.'))}
                            label="Price"
                            inputProps={{inputMode: "decimal", pattern: "[-+]?[0-9]*[.,]?[0-9]+", type: "tel"}}
                            variant="outlined"/>
                <Button size={'small'} sx={{maxHeight: "56px"}} onClick={generateBid} disabled={priceError || quantityError || !quantity || !price} fullWidth variant="contained" color={sell && (+price === sell.price) ? "success" : "primary"}>{sell && (+price === sell.price) ? "Buy" : "Post bid"}</Button>
            </Box>

            <Box sx={{display: loading || response ? 'flex' : 'none', flexDirection: "column", alignItems: 'center', gap: 2, my: 2, p: 2, borderRadius: 1, backgroundColor: 'grey.800'}}>
                {loading && <CircularProgress sx={(theme) => ({color: theme.palette.primary.contrastText})} />}
                {response && response.status === REQUEST_STATUS.SUCCESS &&
                    <Box sx={{display: 'flex', flexDirection: "column", gap: 2, alignItems: 'center'}}>
                        <CheckCircle sx={(theme) => ({fill: theme.palette.primary.contrastText})} fontSize="large" />
                        <Typography sx={{color: 'primary.contrastText'}}>Your bid has been successfully created</Typography>
                        <Button onClick={() => history.push(`/bid/${response.data.id}`)} color={"primary"} variant="contained">Bid details</Button>
                    </Box>
                }
            </Box>
        </Box>

    )
}