import React from 'react'
import {Box} from "@mui/material";
import SetPassword from "../../components/auth/setPassword";
import ResetLink from "../../components/auth/resetLink";

export default function NewPasswordPage() {


    return <Box sx={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ResetLink/>
    </Box>
}