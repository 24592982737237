import React, {useEffect, useState} from 'react'
import {Box, Button, CircularProgress, Container, Typography} from "@mui/material";
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest} from "../hooks/request";
import {useHistory, useParams} from "react-router-dom";
import CustomerBidList from "../components/customerBidList";
import ActionRequiredBid from "../components/actionRequiredBid";

export default function BidDetailsPage({}) {
    const {bidId} = useParams()
    const {response, loading} = useRequest(`customer/bid/details/${bidId}`)
    const [bid, setBid] = useState(null)
    const {request: newRequest, loading: newLoading, response: newResponse} = useLazyRequest('customer/bid', REQUEST_METHOD.PUT)
    const {request: cancelRequest, loading: cancelLoading, response: cancelResponse} = useLazyRequest(`customer/cancel-bid`, REQUEST_METHOD.PUT)
    const history = useHistory()

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setBid(response.data)
        }
    }, [response])

    useEffect(() => {
        if (cancelResponse && cancelResponse.status === REQUEST_STATUS.SUCCESS) {
            setBid(cancelResponse.data)
        }
    }, [cancelResponse])

    useEffect(() => {
        if (newResponse && newResponse.status === REQUEST_STATUS.SUCCESS) {
            setBid(newResponse.data)
        }
    }, [newResponse])

    const cancelBid = () => {
        cancelRequest(null, {id: bidId})
        //TODO open modal validation
    }

    const updateBid = (price) => {
        newRequest({}, {
            old: bid.id,
            newBid:
                {
                    price: +price,
                    stock: bid.stock,
                    productId: bid.productId,
                    parentId: bid.parentId,
                    meta: {}
                }
        })
    }

    return (
        <Container maxWidth={"md"}>
            {<Button onClick={() => history.push('/bid')}>Back</Button>}

            <Typography color={'text.primary'} sx={{textAlign: 'left', mb: 3, fontWeight: 'bold'}} variant="h4">My bids</Typography>
            {!bid || loading || cancelLoading || newLoading ?
                <Box sx={{display: 'flex', justifyContent: 'center', py: 2}}>
                    <CircularProgress size={50}/>
                </Box>
                :
                <Box>
                    <CustomerBidList bids={[bid]}/>

                    <ActionRequiredBid customerBid={bid} onCancel={cancelBid} onNewBid={updateBid}/>

                    <Box sx={{display: 'flex', justifyContent: 'flex-end', my: 2}}>
                        {bid.state === 'OPEN' && !bid.stateExtra && <Button onClick={cancelBid} disabled={bid.state !== 'OPEN'} color='error' variant="contained">Cancel
                            bid</Button>}
                    </Box>
                </Box>
            }
        </Container>
    )
}