import React, {useEffect, useState} from 'react'
import {Box, Button, TextField, Typography} from "@mui/material";

export default function ActionRequiredBid({customerBid, onCancel, onNewBid}) {
    const proposedPrice = customerBid.meta?.proposedPrice
    const [price, setPrice] = useState(proposedPrice ? proposedPrice : customerBid.price + 5)
    const [priceError, setPriceError] = useState()

    useEffect(() => {
        if (price <= customerBid.price) {
            setPriceError("You cannot offer the same or a lower price.")
        } else {
            setPriceError(undefined)
        }
    }, [price])
    if (customerBid.state !== "OPEN" || customerBid.stateExtra !== "REJECTED") return (<></>)

    return (
        <Box >
            <Typography color="text.primary" sx={{my: 2}} fontWeight="bold" variant="h5">Your bid has been rejected</Typography>

            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <Typography color="text.primary">{!!proposedPrice ? `Your price has been rejected. Current counteroffer : ${proposedPrice}€` : 'Please make a new offer or this bid well be automatically cancelled.'}</Typography>
                <TextField onFocus={(event => event.target.select())} sx={{flex: 1}} error={priceError} helperText={priceError} fullWidth value={price} onChange={({target: {value}}) => setPrice(value.replace(/[,]/g, '.'))} label={!!proposedPrice ? "New price" : "Requested price"} inputProps={{inputMode: "decimal", pattern: "[-+]?[0-9]*[.,]?[0-9]+", type: "tel"}} variant="outlined"/>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button variant="contained" disabled={priceError} onClick={() => onNewBid(+price)}>{(proposedPrice && price !== proposedPrice) ? 'Bid' :'Buy'}</Button>
                </Box>
            </Box>
        </Box>
    )
}