import React, {useEffect, useState} from 'react'
import {useHistory, useLocation, useParams} from "react-router-dom";
import {REQUEST_STATUS, useRequest} from "../hooks/request";
import {Box, Button, Container, Typography} from "@mui/material";
import Loading from "../components/loading";
import CreateBidForm from "../components/createBidForm";
import OfferList from "../components/offerList";

export default function CreateBidPage({}) {
    const history       = useHistory()
    const { productId } = useParams();

    const {response, loading} = useRequest(`customer/product/${productId}`)

    const [product, setProduct] = useState()
    const [offer, setOffer]     = useState()

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            let product = response.data

            setProduct(product)
            setOffer(product.book[0])
        }
    }, [response])

    return (
        <Container maxWidth={"md"}>

            <Box sx={{my: 2}}>
                {history && history.goBack && <Button color="inherit" onClick={history.goBack}>Back</Button>}
                <Typography color={'text.primary'} variant="h4" component="h2">{product && product.name}</Typography>
            </Box>


            <Loading loading={loading}>
                <OfferList offers={offer ? [offer] : []}/>
                <CreateBidForm sell={offer}/>
            </Loading>
        </Container>
    )
}